<template>
  <BaseModal
    ref="modal"
    :title="title"
    max-width="tw-max-w-4xl"
  >
    <WizardRentalAgreementStep1
      v-show="activeStep === 1"
      v-bind="{
        propertyId,
        initialValues,
        thirdPartyAccounts,
        rentalTerms
      }"
      @finished="moveForward"
    />
    <WizardRentalAgreementStep2
      v-show="activeStep === 2"
      v-bind="{
        propertyId
      }"
      @finished="moveForward"
      @go-back="goBack"
    />
    <WizardRentalAgreementStep3
      v-show="activeStep === 3"
      v-bind="{
        propertyId,
        initialValues,
        stepValues
      }"
      @finished="hide"
      @go-back="goBack"
    />
  </BaseModal>
</template>

<script>
import EventBus from '@/components/iam/bus'
import { getPropertyRentalAgreement, getRentalTerms } from '@/services/properties'
import { getThirdPartyAccounts } from '@/services/transactions'
import { currency, parseFileObj } from '@/utils/helpers'
import { errorModal, warningModal } from '@/modalMessages'

import WizardRentalAgreementStep1 from '@/components/properties/WizardRentalAgreementStep1'
import WizardRentalAgreementStep2 from '@/components/properties/WizardRentalAgreementStep2'
import WizardRentalAgreementStep3 from '@/components/properties/WizardRentalAgreementStep3'

export default {
  name: 'WizardRentalAgreement',
  components: {
    WizardRentalAgreementStep1,
    WizardRentalAgreementStep2,
    WizardRentalAgreementStep3
  },
  props: {
    property: {
      type: Object,
      required: true
    }
  },
  computed: {
    propertyId () {
      return this.property.id
    },
    title () {
      return `Stap ${this.activeStep}: Afhandeling huurovereenkomst`
    }
  },
  data () {
    return {
      stepValues: {},
      initialValues: null,
      activeStep: 1,
      thirdPartyAccounts: [],
      rentalTerms: []
    }
  },
  methods: {
    currency,
    goBack (step) {
      this.activeStep = step - 1
    },
    hide () {
      EventBus.$emit('initProperty')
      this.$refs.modal.hide()
    },
    moveForward (data, step) {
      this.$set(this.stepValues, `step${step}`, data)
      this.activeStep = step + 1
    },
    async show () {
      const initialValuesResponse = await this.fetchInitialValues()
      if (!this.initialValues) return
      const thirdPartyAccountsResponse = await this.fetchThirdPartyAccounts()
      const rentalTermsResponse = await this.fetchRentalTerms()
      this.$refs.modal.show()
      return [initialValuesResponse, thirdPartyAccountsResponse, rentalTermsResponse]
    },
    async fetchInitialValues () {
      try {
        const response = await getPropertyRentalAgreement(this.propertyId)
        this.initialValues = response.data
        this.$set(this.initialValues, 'commission_type_out', response.data?.transaction_data?.commission_type_in)
        this.$set(this.initialValues, 'commission_fix_out', response.data?.transaction_data?.commission_fix_in)
        this.$set(this.initialValues, 'formula_type_out', response.data?.transaction_data.formula_type_in)
        this.$set(this.initialValues, 'rental_agreement_file', parseFileObj(response.data?.agreement_file))
        return response
      } catch (error) {
        if (error.response?.data?.code === 'TRANSACTION_NOT_FOUND') {
          return warningModal('Dit dossier heeft geen transactie. Gelieve manueel een transactie aan te maken om de afhandeling verder te zetten.')
        } else if (error.response?.data?.code === 'COMMISSION_FEE_PRODUCT_NOT_FOUND') {
          return warningModal('Er kon geen commissieproduct gelinkt worden aan dit dossier. Contacteer de IT-helpdesk.')
        } else {
          return errorModal('Er ging iets mis bij laden van afhandelingswizard.')
        }
      }
    },
    async fetchThirdPartyAccounts () {
      // transaction_type 3 = rental, transaction_type 5 = rental management
      const params = { params: { property: this.propertyId, transaction_types: [3, 5] } }
      const response = await getThirdPartyAccounts(params)
      this.thirdPartyAccounts = response?.data?.results
      return response
    },
    async fetchRentalTerms () {
      const params = { property: this.propertyId }
      const response = await getRentalTerms(params)
      this.rentalTerms = response.data?.results.map(rentalTerm => { return { value: rentalTerm.id, label: rentalTerm.name, is_default: rentalTerm.is_default } })
    }
  }
}
</script>

<template>
  <div class="tw-grid md:tw-grid-cols-3 md:tw-divide-x tw-gap-8 tw-rounded-md tw-shadow-card tw-p-4">
    <div
      @dragover.prevent
      @drop.prevent="onDrop"
    >
      <!-- We don't want to use the same name field, because if this section is used multiple times on the same page, it can cause data conflict. -->
      <FormulateInput
        v-model="contacts"
        type="group"
        :label="labels.contactsInputLabel"
        :repeatable="true"
        remove-position="after"
        :validation="contactValidations"
        group-repeatable-class="tw-relative"
        outer-class="tw-m-0"
      >
        <template #default="{ model, index }">
          <div
            draggable
            class="tw-cursor-move tw-my-1"
            @dragstart="onDragStart($event, {
              index,
              source: `contact${index}`,
              contact: model[index],
            })"
          >
            <FormulateInput
              :name="contactRole"
              type="autocomplete"
              auto-complete-type="contact"
              placeholder="Selecteer een contact"
              validation="required"
              :validation-messages="{
                required: 'Contact is verplicht.'
              }"
              :hide-clear-button="true"
              :input-class="['tw-rounded-l-none']"
              :element-class="['tw-flex tw-items-center']"
              outer-class="tw-m-0 tw-w-full"
              @input="handleGoogleReview($event, model[index])"
            >
              <template #label="{ model }">
                <div class="tw-flex tw-justify-end">
                  <button
                    v-if="shouldShowCreateButton(model)"
                    type="button"
                    :title="labels.addContact"
                    class="link tw-font-semibold"
                    @click="showContactCreateModal"
                  >
                    <i class="far fa-user-plus" />
                  </button>
                  <router-link
                    v-else
                    :to="{ name: 'ContactDetails', params: { id: model.id } }"
                    target="_blank"
                  >
                    <i class="far fa-info-circle" />
                  </router-link>
                </div>
              </template>

              <div slot="prefix" class="input-unit tw-text-base tw-rounded-l tw-rounded-r-none tw-border-l tw-border-r-0">
                <i class="fas fa-arrows" />
              </div>

              <template #help="{ model }">
                <FormulateInput
                  v-if="model.id && askGoogleReview"
                  type="checkbox"
                  name="google_review_contact"
                  label="Google review opvragen"
                  :value="model.id"
                  outer-class="tw-my-1"
                />
              </template>
            </FormulateInput>
          </div>
        </template>

        <template #addmore="{ addMore }">
          <button
            type="button"
            class="tw-my-4 tw-font-semibold link"
            @click="addMore"
          >
            {{ labels.addContact }}
          </button>
        </template>

        <template #remove="{ model, index, removeItem }">
          <!-- If disclaimer not accepted, disable removing -->
          <button
            type="button"
            title="Verwijderen"
            :class="[
              'tw-absolute tw-right-0',
              'tw-px-3 tw-text-danger',
              'hover:tw-text-darken-danger',
              'disabled:tw-text-gray-cc disabled:tw-cursor-not-allowed',
              model[index] && model[index][contactRole]
              && model[index][contactRole].id && askGoogleReview
                ? 'tw-inset-y-2'
                : 'tw-inset-y-1/2'
            ]"
            @click="removeContact(index, removeItem, model[index])"
          >
            <i class="fas fa-trash" />
          </button>
        </template>
      </FormulateInput>
      <ContactCreateModal
        ref="contactCreateModal"
        :redirect="false"
        @success="newContactCreated"
      />
      <div v-if="contactsWithoutEmailAddress">
        <i class="fa fa-exclamation-triangle tw-text-xs tw-text-darken-warning tw-mr-0.5" />
        Contact zonder e-mailadres ({{ contactsWithoutEmailAddress }})
      </div>
    </div>
    <EntityInvoiceContacts
      ref="invoiceContacts"
      :form-values="formValues"
      :property-id="propertyId"
      :project-id="projectId"
      :contacts="contacts"
      :contact-role="contactRole"
      :show-disclaimer="showDisclaimer"
      class="tw-col-span-2"
    />
    <ContactMergeModal
      ref="mergeModal"
      :contacts="contactsToMerge"
      :automatic-pop-up="true"
      header-text="Er zijn dubbele eigenaars gevonden op basis van e-mailadres. Voeg deze samen of klik onderaan op 'Deze stap overslaan' om verder te gaan zonder samen te voegen."
      @contactsMergeCancelled="handleContactsMergeCancelled"
    />
  </div>
</template>

<script>
import { errorModal, successModal } from '@/modalMessages'

import ContactMergeModal from '@/components/contacts/ContactMergeModal'
import ContactCreateModal from '@/components/contacts/ContactCreateModal'
import EntityInvoiceContacts from '@/components/properties/EntityInvoiceContacts'

import { getContactsByIds } from '@/services/contacts'
import { getPropertyOwners, updatePropertyOwners, getPropertyBuyers, getPropertyRenters, updatePropertyBuyers, updatePropertyRenters } from '@/services/properties'
import { getProjectOwners, updateProjectOwners } from '@/services/projects'

export default {
  name: 'EntityContactsFormPartial',
  components: {
    ContactMergeModal,
    ContactCreateModal,
    EntityInvoiceContacts
  },
  props: {
    formValues: {
      type: Object,
      required: true
    },
    projectId: {
      type: [String, Number]
    },
    propertyId: {
      type: [String, Number]
    },
    contactRole: {
      type: String
    },
    showDisclaimer: {
      type: Boolean,
      default: true
    },
    customLabels: {
      type: Object
    },
    askGoogleReview: {
      type: Boolean,
      default: false
    },
    contactValidations: {
      type: [String, Array],
      default: ''
    }
  },
  data () {
    return {
      contacts: [],
      contactsToMerge: [],
      skipDuplicatesCheck: false
    }
  },
  computed: {
    contactsWithoutEmailAddress () {
      // contactObj.[this.contactRole] check is needed here
      const contactObjectsWithoutEmail = this.contacts.filter(contactObj => contactObj[this.contactRole] && !contactObj[this.contactRole].email)
      if (!contactObjectsWithoutEmail) return ''
      return (contactObjectsWithoutEmail.map(contactObj => { return contactObj[this.contactRole].display_name })).join(' / ')
    },
    labels () {
      if (this.customLabels) return this.customLabels

      if (this.contactRole === 'owner') {
        return {
          contactsInputLabel: 'Eigenaars',
          addContact: '+ Eigenaar toevoegen'
        }
      }
      if (this.contactRole === 'buyer') {
        return {
          contactsInputLabel: 'Kopers',
          addContact: '+ Koper toevoegen'
        }
      }
      if (this.contactRole === 'renter') {
        return {
          contactsInputLabel: 'Huurders',
          addContact: '+ Huurder toevoegen'
        }
      }
      return { contactsInputLabel: '', addContact: '' }
    }
  },
  created () {
    this.init()
  },
  methods: {
    showContactCreateModal () {
      this.$refs.contactCreateModal.show()
    },
    newContactCreated (contact) {
      const { type, company_name, first_name, last_name } = contact
      const display_name = type === 'B' ? company_name : `${last_name} ${first_name}`
      const contactsCopyFiltered = this.contacts.filter(contact => typeof contact[this.contactRole] === 'object')
      const obj = {}
      obj[this.contactRole] = { display_name, ...contact }
      contactsCopyFiltered.push(obj)
      this.contacts = contactsCopyFiltered
      return contact
    },
    shouldShowCreateButton (value) {
      // An object means a contact has been selected in the input field
      return typeof value !== 'object'
    },
    onDragStart (event, { index, source, contact }) {
      const contactObj = contact?.[this.contactRole] || contact // In case of newly added contacts, we just have the contact.
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('index', index)
      event.dataTransfer.setData('source', source)
      event.dataTransfer.setData('contact', JSON.stringify(contactObj))
    },
    onDrop (event) {
      this.$refs.invoiceContacts.onDrop(event, { drop: 'outside' })
    },

    handleGoogleReview (event, contact) {
      if (event.id) this.$set(contact, 'google_review_contact', true)
    },

    async init () {
      let response = null
      if (this.contactRole === 'owner') {
        response = this.propertyId
          ? await getPropertyOwners(this.propertyId, { full: '1' })
          : await getProjectOwners(this.projectId, { full: '1' })
      } else if (this.contactRole === 'buyer') {
        response = await getPropertyBuyers(this.propertyId, { full: '1' })
      } else if (this.contactRole === 'renter') {
        response = await getPropertyRenters(this.propertyId, { full: '1' })
      }
      this.contacts = response?.data
      return response
    },
    async triggerContactsMerge (data) {
      if (!data) return
      const contact_ids = [data.contact_id, ...data.duplicate_ids]
      const params = { display: 'full', contact_ids }
      const response = await getContactsByIds(params)
      const contacts = response.data
      const selectedContact = contacts.find(c => c.id === parseInt(data.contact_id))
      const contactsToMerge = [selectedContact, ...contacts.filter(c => c.id !== parseInt(data.contact_id))]
      this.contactsToMerge = JSON.parse(JSON.stringify(contactsToMerge.concat(contactsToMerge[0])))
      this.$refs.mergeModal.showModal()
      return response
    },
    async handleContactsMergeCancelled () {
      this.skipDuplicatesCheck = true
      return await this.submit()
    },
    async updateContacts (relation_ids) {
      try {
        const payload = { relation_ids }
        let response = null
        if (this.contactRole === 'owner') {
          payload.skip_duplicates_check = this.skipDuplicatesCheck
          response = this.propertyId
            ? await updatePropertyOwners(this.propertyId, payload)
            : await updateProjectOwners(this.projectId, payload)
        } else if (this.contactRole === 'buyer') {
          response = await updatePropertyBuyers(this.propertyId, payload)
        } else if (this.contactRole === 'renter') {
          response = await updatePropertyRenters(this.propertyId, payload)
        }
        return response
      } catch (error) {
        if (error?.response?.data?.code?.[0] === 'DUPLICATE_CONTACTS') {
          this.triggerContactsMerge(error?.response?.data?.message)
        } else errorModal('Kan eigenaars niet aanpassen, gelieve opnieuw te proberen.')
        throw error
      }
    },
    async submit () {
      const relation_ids = []
      const google_review_contact_ids = []
      this.contacts.forEach(contact => {
        const contactId = contact[this.contactRole]?.id
        if (contactId) relation_ids.push(contactId)
        if (contact.google_review_contact) google_review_contact_ids.push(contactId)
      })

      this.$refs.invoiceContacts.addDefaultInvoiceContact(true)
      const response = await Promise.all([
        this.updateContacts(relation_ids),
        this.$refs.invoiceContacts.saveInvoiceContacts()
      ])
      successModal('De contacten zijn succesvol opgeslagen')
      this.$emit('goBack')
      return { response, google_review_contact_ids }
    },
    async removeContact (index, action, object) {
      // We need to check if the contact is linked to an invoice contact.
      // If it is, then we first need to remove it from the invoice contact.
      // Then we need to ask the user for confirmation.
      const contactObj = object[this.contactRole]
      const result = await this.$refs.invoiceContacts.removeContactFromInvoiceContacts(contactObj.id)
      if (result) action(index)
    }
  }
}
</script>

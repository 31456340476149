<template>
  <BaseModal
    ref="modal"
    :title="title"
    max-width="tw-max-w-5xl"
  >
    <WizardSalesAgreementStep1
      v-show="activeStep === 1"
      v-bind="{
        propertyId,
        initialValues,
        suspensiveConditions,
        thirdPartyAccounts
      }"
      @finished="moveForward"
      @cancel="showPropertyCancelDossierModal"
    />
    <WizardSalesAgreementStep2
      v-show="activeStep === 2"
      v-bind="{
        propertyId,
        stepValues,
        initialValues
      }"
      @finished="moveForward"
      @go-back="goBack"
    />
    <WizardSalesAgreementStep3
      v-show="activeStep === 3"
      v-bind="{
        property,
        stepValues
      }"
      @finished="salesAgreementSubmitted"
      @go-back="goBack"
    />
    <WizardSalesAgreementStep4
      v-if="activeStep === 4"
      v-bind="{
        property,
        shouldMakeProformaInvoice
      }"
    />
    <PropertyCancelDossierModal
      ref="propertyCancelDossierModal"
      :property-id="propertyId"
      @dossier-cancelled="dossierCancelled"
    />
  </BaseModal>
</template>

<script>
import PropertyCancelDossierModal from '@/components/properties/PropertyCancelDossierModal.vue'
import WizardSalesAgreementStep1 from '@/components/properties/WizardSalesAgreementStep1'
import WizardSalesAgreementStep2 from '@/components/properties/WizardSalesAgreementStep2'
import WizardSalesAgreementStep3 from '@/components/properties/WizardSalesAgreementStep3'
import { errorModal, warningModal } from '@/modalMessages'

import { getSuspensiveConditions } from '@/services/apiService'
import { getPropertySalesAgreement } from '@/services/properties'
import { getThirdPartyAccounts } from '@/services/transactions'

export default {
  name: 'WizardSalesAgreement',
  components: {
    PropertyCancelDossierModal,
    WizardSalesAgreementStep1,
    WizardSalesAgreementStep2,
    WizardSalesAgreementStep3,
    WizardSalesAgreementStep4: () =>
      import(/* webpackChunkName: "WizardSalesAgreementStep4" */ '@/components/properties/WizardSalesAgreementStep4')
  },
  props: {
    property: {
      type: Object,
      required: true
    }
  },
  computed: {
    propertyId () {
      return this.property.id
    },
    agreementLabel () {
      return this.initialValues?.transaction_type === 2 ? 'verkoopovereenkomst' : 'overnameovereenkomst'
    },
    title () {
      return `Stap ${this.activeStep}: Afhandeling ondertekende ${this.agreementLabel}`
    },
    shouldMakeProformaInvoice () {
      let result = {
        shouldShowMessage: false,
        reason: 'Opgelet, het voorschot voor deze transactie wordt niet op onze rekening geplaatst.'
      }
      if (!this.stepValues?.step1) return result

      const { advance_payment, commission_fix_out, advance_payment_account } = this.stepValues.step1
      if (!advance_payment) result.shouldShowMessage = false
      if (parseInt(advance_payment) < parseInt(commission_fix_out)) {
        result = {
          shouldShowMessage: true,
          reason: 'Opgelet, het voorschotbedrag is lager dan het commissiebedrag.'
        }
      }
      if (parseInt(advance_payment_account) === 2) result.shouldShowMessage = true
      return result
    }
  },
  data () {
    return {
      stepValues: {},
      activeStep: 1,
      initialValues: null,
      suspensiveConditions: [],
      thirdPartyAccounts: []
    }
  },
  methods: {
    goBack (step) {
      this.activeStep = step - 1
    },
    moveForward (data, step) {
      this.$set(this.stepValues, `step${step}`, data)
      this.activeStep = step + 1
    },
    salesAgreementSubmitted (data, step) {
      this.$emit('submitted')
      this.$set(this.stepValues, `step${3}`, data)
      this.activeStep = step + 1
    },
    dossierCancelled () {
      this.$refs.modal.hide()
    },
    showPropertyCancelDossierModal () {
      return this.$refs.propertyCancelDossierModal.show()
    },

    async show () {
      await this.fetchInitialValues()
      const response = await Promise.all([
        this.fetchSuspensiveConditions(),
        this.fetchThirdPartyAccounts()
      ])
      if (!this.initialValues) return
      this.$refs.modal.show()
      return response
    },
    async fetchInitialValues () {
      try {
        const response = await getPropertySalesAgreement(this.propertyId)
        this.initialValues = response.data
        return response
      } catch (error) {
        if (error.response?.data?.code === 'TRANSACTION_NOT_FOUND') {
          return warningModal('Dit dossier heeft geen transactie. Gelieve manueel een transactie aan te maken om de afhandeling verder te zetten.')
        } else if (error.response?.data?.code === 'COMMISSION_FEE_PRODUCT_NOT_FOUND') {
          return warningModal('Er kon geen commissieproduct gelinkt worden aan dit dossier. Contacteer de IT-helpdesk.')
        } else {
          return errorModal('Er ging iets mis bij laden van compromiswizard.')
        }
      }
    },
    async fetchSuspensiveConditions () {
      const response = await getSuspensiveConditions()
      const conditions = response.data?.results || []
      this.suspensiveConditions = conditions.map(({ id, name }) => {
        return { label: name, value: id }
      })
      return response
    },
    async fetchThirdPartyAccounts () {
      const params = { params: { property: this.propertyId, transaction_types: [2, 4] } }
      const response = await getThirdPartyAccounts(params)
      const thirdPartyAccounts = response.data?.results || []
      this.thirdPartyAccounts = thirdPartyAccounts.map(({ id, display_name }) => {
        return { label: display_name, value: id }
      })
      return response
    }
  }
}
</script>
